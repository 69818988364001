import './style.css'
// THREE imports
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'

// GSAP imports
import gsap from 'gsap'

const interActiveCode = () => {
    
    // Main Interactions Code
    const mainInteractionsCode = () => {
        // Scroll Triggers
        gsap.registerPlugin(ScrollTrigger)

        // -----------------------------------------------------------------
        /**
         * Base
         */

        // Canvas
        const canvas = document.querySelector('.mainInteractions')
        const nav = document.querySelector('.nav')

        // Scene
        const scene = new THREE.Scene()
        // scene.background = new THREE.Color(0xF8F0E3)

        /**
         * Loaders
         */
        // Loading Manager

        const loadingManager = new THREE.LoadingManager(
            // Loaded
            () => {
                gsap.to('.loadingPage', {duration: 1, opacity: 0})
                setTimeout(() => {
                    topTextAnimations()
                }, 1000)
            },
            // Progress
            (itemUrl, itemsLoaded, itemsTotal) => {
                // console.log(itemsLoaded/itemsTotal)
            }
        )

        // Favicon Change
        const iconLinks = ["./images/No BG/GreenCircle.png", "./images/No BG/OrangeTriangle.png", "./images/No BG/BlueRectangle.png"]
        let currenctIconIndex = 3
        const favicon = document.querySelector('.favicon')

        const changeIcon = () => {
            if (currenctIconIndex < (iconLinks.length - 1)) {
                currenctIconIndex++
            }
            else {
                currenctIconIndex = 0
            }
            favicon.href = iconLinks[currenctIconIndex]
            setTimeout(() => {
                changeIcon()
            }, 5000)
        }

        changeIcon()

        // Texture loader
        const textureLoader = new THREE.TextureLoader(loadingManager)

        // Logo Texture Loads
        const logoColors = [new THREE.Color(0x3d7c4a).convertSRGBToLinear(), new THREE.Color(0xf26b30).convertSRGBToLinear(), new THREE.Color(0x292c56).convertSRGBToLinear()]

        const greyCircle = textureLoader.load('./images/No BG/GreyCircle.png')
        const greyTriangle = textureLoader.load('./images/No BG/GreyTriangle.png')
        greyTriangle.encoding = THREE.sRGBEncoding
        const greyRectangle = textureLoader.load('./images/No BG/GreyRectangle.png')

        // Draco loader
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('draco/')

        // GLTF loader
        const gltfLoader = new GLTFLoader(loadingManager)
        gltfLoader.setDRACOLoader(dracoLoader)

        // Font Loader
        const fontLoader = new FontLoader()

        // Lighting

        const ambientLight = new THREE.AmbientLight(0xffffff, 1)
        scene.add(ambientLight)

        /**
         * Sizes
         */
        const sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        window.addEventListener('resize', () => {    
            // Update sizes
            sizes.width = window.innerWidth
            sizes.height = window.innerHeight

            // Update camera
            camera.aspect = sizes.width / sizes.height
            camera.updateProjectionMatrix()

            // Update renderer
            renderer.setSize(sizes.width, sizes.height)
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        })

        // Objects
        const cameraGroup = new THREE.Group

        // meshParameters
        const meshParameters =  {
            count: 15,
            resize: 0.3
        }

        const meshShapesDimensionRatios = [66/64]

        let currentMeshIndex = 0

        let meshGroup = []
        let meshRotations = []

        let circleMeshes = []
        let triangleMeshes = []
        let rectangleMeshes = []

        let circleCount = 0
        let triangleCount = 0
        let rectangleCount = 0

        let meshM

        // Make Meshes
        for (let i = 0; i < meshParameters.count; i++) {
            const meshG = new THREE.PlaneGeometry(meshShapesDimensionRatios[0]*meshParameters.resize,1*meshParameters.resize)

            if (currentMeshIndex == 0) {
                meshM = new THREE.MeshBasicMaterial({
                    map: greyCircle,
                    transparent: true,
                    side: THREE.DoubleSide,
                    depthWrite: false
                })
            }   
            else if (currentMeshIndex == 1) {
                meshM = new THREE.MeshBasicMaterial({
                    map: greyTriangle,
                    transparent: true,
                    side: THREE.DoubleSide,
                    depthWrite: false
                })
            }
            else if (currentMeshIndex == 2) {
                meshM = new THREE.MeshBasicMaterial({
                    map: greyRectangle,
                    transparent: true,
                    side: THREE.DoubleSide,
                    depthWrite: false
                })
            }

            const mesh = new THREE.Mesh(meshG, meshM)
            const x = (Math.random() - 0.5) * 9
            const y = (Math.random() - 0.5) * 5
            const z = -0.1 -Math.random()*0.4

            const rx = (Math.random() - 0.5) * 1
            const ry = (Math.random() - 0.5) * 1
            const rz = (Math.random() - 0.5) * 1

            mesh.position.set(x,y,z)
            meshGroup[i] = mesh
            meshRotations[i] = [rx, ry, rz]
            scene.add(mesh)

            if (currentMeshIndex == 2) {
                currentMeshIndex = 0
                rectangleMeshes[rectangleCount] = mesh
                rectangleCount++
            }
            else if (currentMeshIndex == 1) {
                currentMeshIndex++
                triangleMeshes[triangleCount] = mesh
                triangleCount++
            }
            else if (currentMeshIndex == 0) {
                currentMeshIndex++
                circleMeshes[circleCount] = mesh
                circleCount++
            }

        }

        // --------------------

        /**
         * Camera
         */
        // Base camera
        const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
        camera.position.set(0,0,5)
        scene.add(camera)

        // Controls
        const controls = new OrbitControls(camera, canvas)
        controls.enabled = false

        controls.enableDamping = true
        controls.maxPolarAngle = Math.PI/2
        // controls.minAzimuthAngle = Math.PI*0/180
        // controls.maxAzimuthAngle = Math.PI*90/180
        controls.minDistance = 12  
        controls.maxDistance = 80

        /**
         * Renderer
         */
        const renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            antialias: true,
            alpha: true,
            gammaOutput: true,
            gammaFactor: 2.2
        })
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        renderer.shadowMap.enabled = true
        renderer.shadowMap.type = THREE.PCFSoftShadowMap
        renderer.outputEncoding = THREE.sRGBEncoding
        renderer.toneMapping = THREE.CineonToneMapping

        // Parallax Camera Group
        cameraGroup.add(camera)
        scene.add(cameraGroup)

        // Events
        const mouse = {
            x: 0,
            y: 0
        }

        // Raycaster Events
        const raycaster = new THREE.Raycaster()
        const pointer = new THREE.Vector3()
        const point = new THREE.Vector3()

        let nearestCircleIndex = 0
        let prevCircleDistance = 0
        let currentCircleDistance = 0

        let nearestTriangleIndex = 0
        let prevTriangleDistance = 0
        let currentTriangleDistance = 0

        let nearestRectangleIndex = 0
        let prevRectangleDistance = 0
        let currentRectangleDistance = 0

        let rotationMultiplier = 1

        let mouseOnTree = false
        let treeTime = 0

        // Lines
        let circleLinePoints = {
            a: {x: 0, y: 0},
            b: {x: 0, y: 0, z: 0}
        }
        let circleLinePush = []
        let circleLineG = null
        let circleLineM = new THREE.LineDashedMaterial({
            color: logoColors[0],
            linewidth: 1,
            scale: 1,
            dashSize: 0.01,
            gapSize: 0.1
        })
        let circleLine = null

        let triangleLinePoints = {
            a: {x: 0, y: 0},
            b: {x: 0, y: 0, z: 0}
        }
        let triangleLinePush = []
        let triangleLineG = null
        let triangleLineM = new THREE.LineDashedMaterial({
            color: logoColors[1],
            linewidth: 1,
            scale: 1,
            dashSize: 0.01,
            gapSize: 0.1
        })
        let triangleLine = null

        let rectangleLinePoints = {
            a: {x: 0, y: 0},
            b: {x: 0, y: 0, z: 0}
        }
        let rectangleLinePush = []
        let rectangleLineG = null
        let rectangleLineM = new THREE.LineDashedMaterial({
            color: logoColors[2],
            linewidth: 1,
            scale: 1,
            dashSize: 0.01,
            gapSize: 0.1
        })
        let rectangleLine = null

        window.addEventListener('pointermove', (e) => {
            // Line Resets
            circleLinePush = []
            scene.remove(circleLine)

            triangleLinePush = []
            scene.remove(triangleLine)

            rectangleLinePush = []
            scene.remove(rectangleLine)

            // 2D
            mouse.x = e.clientX/window.innerWidth - 0.5
            mouse.y = -(e.clientY/window.innerHeight - 0.5)

            // Earth Rotation
            if (mouse.x > 0) {
                rotationMultiplier = -1
            }
            else {
                rotationMultiplier = 1
            }
            gsap.to('.earthRotation', {duration: 1, rotationZ: (mouse.x + 0.5)*30})

            // Hero CTA
            gsap.to('#heroSectionCTA', {duration: 0, x: mouse.x * 20, y: -mouse.y * 20})
            gsap.to('.hoverCTA', {duration: 1, x: mouse.x * 20, y: -mouse.y * 20})

            // Tree Arrow
            if (mouse.x > 0.2 && mouse.y < 0.2 && mouse.y > -0.2) {
                gsap.to('#heroPicture3', {duration: 1, x: -100})
                gsap.to('#heroPicture2', {duration: 1, delay: 0.1, x: -60})
                gsap.to('#heroPicture1', {duration: 1, delay: 0.1, x: -40})
                mouseOnTree = true
            }
            else {
                gsap.to('#heroPicture3', {duration: 1, x: 0})
                gsap.to('#heroPicture2', {duration: 1, delay: 0.1, x: 0})
                gsap.to('#heroPicture1', {duration: 1, delay: 0.1, x: 0})
                mouseOnTree = false
            }

            // 3D
            // Update Pointer Coordinates
            pointer.set(
            ( e.clientX / window.innerWidth ) * 2 - 1,
            - ( e.clientY / window.innerHeight ) * 2 + 1,
            0.5
            )

            // Match Mouse and 3D Pointer Coordinates
            pointer.unproject(camera)
            pointer.sub(camera.position).normalize()
            let distance = -(camera.position.z) / pointer.z
            point.copy(camera.position).add((pointer.multiplyScalar(distance)))

            // Check for Affected Circles
            for (let i = 0; i < circleMeshes.length; i++) {
                if (i == 0) {
                    nearestCircleIndex = i
                    prevCircleDistance = ((circleMeshes[i].position.x - pointer.x)**2 + (circleMeshes[i].position.y - pointer.y)**2)**0.5
                }
                else if (i > 0 && i < (circleMeshes.length - 1)) {
                    currentCircleDistance = ((circleMeshes[i].position.x - pointer.x)**2 + (circleMeshes[i].position.y - pointer.y)**2)**0.5
                    if (currentCircleDistance < prevCircleDistance) {
                        prevCircleDistance = currentCircleDistance
                        nearestCircleIndex = i
                    }
                }
                else if (i == (circleMeshes.length - 1)) {
                    currentCircleDistance = ((circleMeshes[i].position.x - pointer.x)**2 + (circleMeshes[i].position.y - pointer.y)**2)**0.5
                    if (currentCircleDistance < prevCircleDistance) {
                        prevCircleDistance = currentCircleDistance
                        nearestCircleIndex = i
                    }

                    circleMeshes[nearestCircleIndex].material.color = logoColors[0]

                    circleLinePoints.b.x = circleMeshes[nearestCircleIndex].position.x
                    circleLinePoints.b.y = circleMeshes[nearestCircleIndex].position.y
                    circleLinePoints.b.z = circleMeshes[nearestCircleIndex].position.z
                    for (let j = 0; j < circleMeshes.length; j++) {
                        if (j !== nearestCircleIndex) {
                            circleMeshes[j].material.color = new THREE.Color(0xedf0f0)
                        }
                    }
                }
            }

            // Check for Affected Triangles
            for (let i = 0; i < triangleMeshes.length; i++) {
                if (i == 0) {
                    nearestTriangleIndex = i
                    prevTriangleDistance = ((triangleMeshes[i].position.x - pointer.x)**2 + (triangleMeshes[i].position.y - pointer.y)**2)**0.5
                }
                else if (i > 0 && i < (triangleMeshes.length - 1)) {
                    currentTriangleDistance = ((triangleMeshes[i].position.x - pointer.x)**2 + (triangleMeshes[i].position.y - pointer.y)**2)**0.5
                    if (currentTriangleDistance < prevTriangleDistance) {
                        prevTriangleDistance = currentTriangleDistance
                        nearestTriangleIndex = i
                    }
                }
                else if (i == (triangleMeshes.length - 1)) {
                    currentTriangleDistance = ((triangleMeshes[i].position.x - pointer.x)**2 + (triangleMeshes[i].position.y - pointer.y)**2)**0.5
                    if (currentTriangleDistance < prevTriangleDistance) {
                        prevTriangleDistance = currentTriangleDistance
                        nearestTriangleIndex = i
                    }

                    triangleMeshes[nearestTriangleIndex].material.color = logoColors[1]

                    triangleLinePoints.b.x = triangleMeshes[nearestTriangleIndex].position.x
                    triangleLinePoints.b.y = triangleMeshes[nearestTriangleIndex].position.y
                    triangleLinePoints.b.z = triangleMeshes[nearestTriangleIndex].position.z
                    for (let j = 0; j < triangleMeshes.length; j++) {
                        if (j !== nearestTriangleIndex) {
                            triangleMeshes[j].material.color = new THREE.Color(0xedf0f0)
                        }
                    }
                }
            }

            // Check for Affected Rectangles
            for (let i = 0; i < rectangleMeshes.length; i++) {
                if (i == 0) {
                    nearestRectangleIndex = i
                    prevRectangleDistance = ((rectangleMeshes[i].position.x - pointer.x)**2 + (rectangleMeshes[i].position.y - pointer.y)**2)**0.5
                }
                else if (i > 0 && i < (rectangleMeshes.length - 1)) {
                    currentRectangleDistance = ((rectangleMeshes[i].position.x - pointer.x)**2 + (rectangleMeshes[i].position.y - pointer.y)**2)**0.5
                    if (currentRectangleDistance < prevRectangleDistance) {
                        prevRectangleDistance = currentRectangleDistance
                        nearestRectangleIndex = i
                    }
                }
                else if (i == (rectangleMeshes.length - 1)) {
                    currentRectangleDistance = ((rectangleMeshes[i].position.x - pointer.x)**2 + (rectangleMeshes[i].position.y - pointer.y)**2)**0.5
                    if (currentRectangleDistance < prevRectangleDistance) {
                        prevRectangleDistance = currentRectangleDistance
                        nearestRectangleIndex = i
                    }

                    rectangleMeshes[nearestRectangleIndex].material.color = logoColors[2]

                    rectangleLinePoints.b.x = rectangleMeshes[nearestRectangleIndex].position.x
                    rectangleLinePoints.b.y = rectangleMeshes[nearestRectangleIndex].position.y
                    rectangleLinePoints.b.z = rectangleMeshes[nearestRectangleIndex].position.z
                    for (let j = 0; j < rectangleMeshes.length; j++) {
                        if (j !== nearestRectangleIndex) {
                            rectangleMeshes[j].material.color = new THREE.Color(0xedf0f0)
                        }
                    }
                }                
            }

            // Pointer Center & Lines
            circleLinePoints.a.x = pointer.x
            circleLinePoints.a.y = pointer.y

            circleLinePush.push(new THREE.Vector3(circleLinePoints.a.x, circleLinePoints.a.y, 0))
            circleLinePush.push(new THREE.Vector3(circleLinePoints.b.x, circleLinePoints.b.y, circleLinePoints.b.z))
            circleLineG = new THREE.BufferGeometry().setFromPoints(circleLinePush)
            circleLine = new THREE.Line( circleLineG, circleLineM )
            circleLine.computeLineDistances()
            scene.add(circleLine)

            triangleLinePoints.a.x = pointer.x
            triangleLinePoints.a.y = pointer.y

            triangleLinePush.push(new THREE.Vector3(triangleLinePoints.a.x, triangleLinePoints.a.y, 0))
            triangleLinePush.push(new THREE.Vector3(triangleLinePoints.b.x, triangleLinePoints.b.y, triangleLinePoints.b.z))
            triangleLineG = new THREE.BufferGeometry().setFromPoints(triangleLinePush)
            triangleLine = new THREE.Line( triangleLineG, triangleLineM )
            triangleLine.computeLineDistances()
            scene.add(triangleLine)

            rectangleLinePoints.a.x = pointer.x
            rectangleLinePoints.a.y = pointer.y

            rectangleLinePush.push(new THREE.Vector3(rectangleLinePoints.a.x, rectangleLinePoints.a.y, 0))
            rectangleLinePush.push(new THREE.Vector3(rectangleLinePoints.b.x, rectangleLinePoints.b.y, rectangleLinePoints.b.z))
            rectangleLineG = new THREE.BufferGeometry().setFromPoints(rectangleLinePush)
            rectangleLine = new THREE.Line( rectangleLineG, rectangleLineM )
            rectangleLine.computeLineDistances()
            scene.add(rectangleLine)
        })

        // Whale Animations
        let mouseOnWhale = false
        let whaleTime = 0

        const whaleAnimations = () => {
            document.querySelector('#whale').addEventListener('mouseenter', () => {
                gsap.to('#whale', {duration: 1, y: -20, x: -50, scale: 1.15})
                mouseOnWhale = true
            })
        
            document.querySelector('#whale').addEventListener('mouseleave', () => {
                gsap.to('#whale', {duration: 1, y: 0, x: 0, rotationZ: 0,  scale: 1})
                mouseOnWhale = false
                whaleTime = 0
            })
        }

         // Earth Animations
         const earthAnimations = () => {
             document.querySelector('#earth').addEventListener('mouseenter', () => {
                 gsap.to('#earth', {duration: 0.5, scale: 1.15})
             })
         
             document.querySelector('#earth').addEventListener('mouseleave', () => {
                 gsap.to('#earth', {duration: 0.5, scale: 1})
             })
         }

        // Hero CTA Animations
        gsap.to('.hoverCTA', {duration: 0, opacity: 0, scale: 0.75})
        gsap.to('#underlineBar1', {duration: 0, transformOrigin: 'left', scaleX: 0})
        gsap.to('#underlineBar2', {duration: 0, transformOrigin: 'left', scaleX: 0})
        
        let isUnderlineAnimating = false

        const heroCTAAnimations = () => {
            document.querySelector('#heroSectionCTA').addEventListener('mouseenter', () => {
                gsap.to('.hoverCTA', {duration: 0.5, opacity: 1, scale: 1.25})
                gsap.to('#heroSectionCTA', {duration: 0.5, scale: 1.25})
                if (isUnderlineAnimating == false) {
                    isUnderlineAnimating = true
                    gsap.to('#underlineBar1', {duration: 0.4, delay: 1, transformOrigin: 'left', scaleX: 1})
                    gsap.to('#underlineBar1', {duration: 0.4, delay: 0.4 + 1, transformOrigin: 'right', scaleX: 0})
                    gsap.to('#underlineBar2', {duration: 0.4, delay: 0.8 + 1, transformOrigin: 'left', scaleX: 1})
                    gsap.to('#underlineBar2', {duration: 0.4, delay: 0.4 + 0.8 + 1, transformOrigin: 'right', scaleX: 0})

                    setTimeout(() => {
                        isUnderlineAnimating = false
                    }, 2700)
                } 
            })

            document.querySelector('#heroSectionCTA').addEventListener('mouseleave', () => {
                gsap.to('.hoverCTA', {duration: 0.5, opacity: 0, scale: 0.75})
                gsap.to('#heroSectionCTA', {duration: 0.5, scale: 1})
            })

            document.querySelector('#heroSectionCTA').addEventListener('click', () => {
                gsap.to('#heroSectionCTA', {duration: 0.1, scale: 1.2})
                gsap.to('#heroSectionCTA', {duration: 0.1, delay: 0.1, scale: 1.25})
                gsap.to('.hoverCTA', {duration: 0.1, scale: 1.35})
                gsap.to('.hoverCTA', {duration: 0.1, delay: 0.1, scale: 1.25})
            })
        }

        // Orange Text Animations
        const smallShapesJump = () => {
            gsap.to('#smallCircle', {duration: 0.3, y: -20, ease: 'Power1.easeOut'})
            gsap.to('#smallCircle', {duration: 0.3, delay: 0.3, y: 0, ease: 'Power1.easeIn'})
            gsap.to('#smallTriangle', {duration: 0.3, delay: 0.1, y: -20, ease: 'Power1.easeOut'})
            gsap.to('#smallTriangle', {duration: 0.3, delay: 0.4, y: 0, ease: 'Power1.easeIn'})
            gsap.to('#smallRectangle', {duration: 0.3, delay: 0.2, y: -20, ease: 'Power1.easeOut'})
            gsap.to('#smallRectangle', {duration: 0.3, delay: 0.5, y: 0, ease: 'Power1.easeIn'})
        }

        const orangeTextAnimations = () => {
            document.querySelector('.orangeText').addEventListener('mouseenter', () => {
                gsap.to('.hoverCTA', {duration: 0.5, opacity: 1, scale: 1.25})
                gsap.to('#heroSectionCTA', {duration: 0.5, scale: 1.25})
                smallShapesJump()

                if (isUnderlineAnimating == false) {
                    isUnderlineAnimating = true
                    gsap.to('#underlineBar1', {duration: 0.4, delay: 1, transformOrigin: 'left', scaleX: 1})
                    gsap.to('#underlineBar1', {duration: 0.4, delay: 0.4 + 1, transformOrigin: 'right', scaleX: 0})
                    gsap.to('#underlineBar2', {duration: 0.4, delay: 0.8 + 1, transformOrigin: 'left', scaleX: 1})
                    gsap.to('#underlineBar2', {duration: 0.4, delay: 0.4 + 0.8 + 1, transformOrigin: 'right', scaleX: 0})

                    setTimeout(() => {
                        isUnderlineAnimating = false
                    }, 2700)
                }
            })

            document.querySelector('.orangeText').addEventListener('mouseleave', () => {
                gsap.to('.hoverCTA', {duration: 0.5, opacity: 0, scale: 0.75})
                gsap.to('#heroSectionCTA', {duration: 0.5, scale: 1})
            })
        }

        // GRP Logo Animations
        let isGRPLogoAnimating = false

        const GRPLogoAnimations = () => {
            document.querySelector('.leftSideNav').addEventListener('mouseenter', () => {
                if (isGRPLogoAnimating == false) {
                    isGRPLogoAnimating = true
                    gsap.to('.CLText', {duration: 0.8, x: '-100%'})
                    gsap.to('.TLText', {duration: 0.8, delay: 0.2, x: '-100%'})
                    gsap.to('.RLText', {duration: 0.8, delay: 0.4, x: '-100%'})
                    gsap.to('.CRText', {duration: 0.8, x: '100%'})
                    gsap.to('.TRText', {duration: 0.8, delay: 0.2, x: '100%'})
                    gsap.to('.RRText', {duration: 0.8, delay: 0.4, x: '100%'})

                    gsap.to('.CLText', {duration: 0, delay: 0.8, x: '100%'})
                    gsap.to('.TLText', {duration: 0, delay: 0.2 + 0.8, x: '100%'})
                    gsap.to('.RLText', {duration: 0, delay: 0.4 + 0.8, x: '100%'})
                    gsap.to('.CRText', {duration: 0, delay: 0.8, x: '-100%'})
                    gsap.to('.TRText', {duration: 0, delay: 0.2 + 0.8, x: '-100%'})
                    gsap.to('.RRText', {duration: 0, delay: 0.4 + 0.8, x: '-100%'})

                    gsap.to('.CLText', {duration: 0.8, delay: 0.82, x: 0})
                    gsap.to('.TLText', {duration: 0.8, delay: 0.2 + 0.82, x: 0})
                    gsap.to('.RLText', {duration: 0.8, delay: 0.4 + 0.82, x: 0})
                    gsap.to('.CRText', {duration: 0.8, delay: 0.82, x: 0})
                    gsap.to('.TRText', {duration: 0.8, delay: 0.2 + 0.82, x: 0})
                    gsap.to('.RRText', {duration: 0.8, delay: 0.4 + 0.82, x: 0})
                    setTimeout(() => {
                        isGRPLogoAnimating = false
                    }, 2100)
                }
            })
        }


        // Starting Sequence:
        gsap.to('.heroDescriptionText', {duration: 0, opacity: 0})
        gsap.to('.its', {duration: 0, y: 200})
        gsap.to('.time', {duration: 0, y: 200})
        gsap.to('.for', {duration: 0,y: 200})
        gsap.to('.innovation', {duration: 0, y: 200})
        gsap.to('#bottomSubHeroText', {duration: 0, opacity: 0, y: -103})
        gsap.to('.CLText', {duration: 0, x: '100%'})
        gsap.to('.TLText', {duration: 0, x: '100%'})
        gsap.to('.RLText', {duration: 0, x: '100%'})
        gsap.to('.CRText', {duration: 0, x: '-100%'})
        gsap.to('.TRText', {duration: 0, x: '-100%'})
        gsap.to('.RRText', {duration: 0, x: '-100%'})
        gsap.to('.topBottomMainHeroSection', {duration: 0, opacity: 0, x: -50})
        gsap.to('.bottomBottomMainHeroSection', {duration: 0, opacity: 0})

         // Top Text Animations
         const topTextAnimations = () => {
            gsap.to('.CLText', {duration: 0.8, x: 0})
            gsap.to('.TLText', {duration: 0.8, delay: 0.2, x: 0})
            gsap.to('.RLText', {duration: 0.8, delay: 0.4, x: 0})
            gsap.to('.CRText', {duration: 0.8, x: 0})
            gsap.to('.TRText', {duration: 0.8, delay: 0.2, x: 0})
            gsap.to('.RRText', {duration: 0.8, delay: 0.4, x: 0})

            gsap.to('.its', {duration: 1.25, y: 0})
            gsap.to('.time', {duration: 1.25, delay: 0.325, y: 0})
            gsap.to('.for', {duration: 1.25, delay: 0.65, y: 0})
            gsap.to('.innovation', {duration: 1.25, delay: 1.25, y: 0})

            gsap.to('#smallCircle', {duration: 0.3, delay: 1.15, y: -20, ease: 'Power1.easeOut'})
            gsap.to('#smallCircle', {duration: 0.3, delay: 0.3 + 1.15, y: 0, ease: 'Power1.easeIn'})

            gsap.to('#smallTriangle', {duration: 0.3, delay: 2.3, y: -20, ease: 'Power1.easeOut'})
            gsap.to('#smallTriangle', {duration: 0.3, delay: 0.3 + 2.3, y: 0, ease: 'Power1.easeIn'})

            gsap.to('#smallRectangle', {duration: 0.3, delay: 3.45, y: -20, ease: 'Power1.easeOut'})
            gsap.to('#smallRectangle', {duration: 0.3, delay: 0.3 + 3.45, y: 0, ease: 'Power1.easeIn'})
            
            gsap.to('#bottomSubHeroText', {duration: 0.5, delay: 0.5 + 2, opacity: 1})
            gsap.to('#bottomSubHeroText', {duration: 0.75, delay: 0.5 + 2, y: 0, ease: 'Power3.easeIn'})

            gsap.to('.orangeText', {duration: 0, delay: 2, opacity: 1, x: -78})
            gsap.to('.V', {duration: 0, delay: 2, opacity: 0})
            gsap.to('.E', {duration: 0, delay: 2, opacity: 0})
            gsap.to('.S', {duration: 0, delay: 2, opacity: 0})
            gsap.to('.T', {duration: 0, delay: 2, opacity: 0})
            gsap.to('.I', {duration: 0, delay: 2, opacity: 0})
            gsap.to('.N', {duration: 0, delay: 2, opacity: 0})
            gsap.to('.G', {duration: 0, delay: 2, opacity: 0})

            gsap.to('.orangeText', {duration: 0.5, delay: 1.25 + 2, x: 10, ease: 'Power1.easeOut'})
            gsap.to('.orangeText', {duration: 0.25, delay: 1.75 + 2, x: 0, ease: 'Power3.easeIn'})
            gsap.to('.orangeText', {duration: 0.6, delay: 1.4 + 2, opacity: 1})

            gsap.to('.V', {duration: 0.1, delay: 1.35 + 0.05 + 2, opacity: 1, ease: 'Power1.easeOut'})
            gsap.to('.E', {duration: 0.1, delay: 1.45 + 0.05 + 2, opacity: 1, ease: 'Power1.easeOut'})
            gsap.to('.S', {duration: 0.1, delay: 1.55 + 0.05 + 2, opacity: 1, ease: 'Power1.easeOut'})
            gsap.to('.T', {duration: 0.1, delay: 1.65 + 0.05 + 2, opacity: 1, ease: 'Power1.easeOut'})
            gsap.to('.I', {duration: 0.1, delay: 1.75 + 0.05 + 2, opacity: 1, ease: 'Power1.easeOut'})
            gsap.to('.N', {duration: 0.1, delay: 1.85 + 0.05 + 2, opacity: 1, ease: 'Power1.easeOut'})
            gsap.to('.G', {duration: 0.1, delay: 1.95 + 0.05 + 2, opacity: 1, ease: 'Power1.easeOut'})

            gsap.to('.hoverCTA', {duration: 0.5, delay: 4, opacity: 1, scale: 1.25})
            gsap.to('#heroSectionCTA', {duration: 0.5, delay: 4, scale: 1.25})
            gsap.to('.hoverCTA', {duration: 0.5, delay: 2.5 + 4, opacity: 0, scale: 0.75})
            gsap.to('#heroSectionCTA', {duration: 0.5, delay: 2.5 + 4, scale: 1})
            gsap.to('#underlineBar1', {duration: 0.4, delay: 1 + 4, transformOrigin: 'left', scaleX: 1})
            gsap.to('#underlineBar1', {duration: 0.4, delay: 0.4 + 1 + 4, transformOrigin: 'right', scaleX: 0})
            gsap.to('#underlineBar2', {duration: 0.4, delay: 0.8 + 1 + 4, transformOrigin: 'left', scaleX: 1})
            gsap.to('#underlineBar2', {duration: 0.4, delay: 0.4 + 0.8 + 1 + 4, transformOrigin: 'right', scaleX: 0})

            gsap.to('.heroDescriptionText', {duration: 1, delay: 3.75, opacity: 1})
            
            gsap.to('#smallCircle', {duration: 0.3, delay: 4, y: -20, ease: 'Power1.easeOut'})
            gsap.to('#smallCircle', {duration: 0.3, delay: 0.3 + 4, y: 0, ease: 'Power1.easeIn'})
            gsap.to('#smallTriangle', {duration: 0.3, delay: 0.1 + 4, y: -20, ease: 'Power1.easeOut'})
            gsap.to('#smallTriangle', {duration: 0.3, delay: 0.4 + 4, y: 0, ease: 'Power1.easeIn'})
            gsap.to('#smallRectangle', {duration: 0.3, delay: 0.2 + 4, y: -20, ease: 'Power1.easeOut'})
            gsap.to('#smallRectangle', {duration: 0.3, delay: 0.5 + 4, y: 0, ease: 'Power1.easeIn'})


            gsap.to('.topBottomMainHeroSection', {duration: 1, delay: 4, opacity: 1, x: 0})
            gsap.to('.bottomBottomMainHeroSection', {duration: 1, delay: 4 + 1, opacity: 1})

            setTimeout(() => {
                whaleAnimations()
                earthAnimations()
                heroCTAAnimations()
                orangeTextAnimations()
                GRPLogoAnimations()
            }, 4300)
        }

        // Contribute CTA Animations
        gsap.to('#CTATriangleLeft', {duration: 0, x: -40})

        const contributeCTAAnimations = () => {
            document.querySelector('.contributeCTAButton').addEventListener('mouseenter', () => {
                gsap.to('.contributeCTAButton', {duration: 0.5, boxShadow: '-4px 4px #f26b30', x: 4, y: -4})
                
                gsap.to('#CTATriangleRight', {duration: 0.5, x: 50})
                gsap.to('#CTATriangleLeft', {duration: 0.5, x: 10})
                gsap.to('.contributeCTAText', {duration: 0.5, x: 10})
            })

            document.querySelector('.contributeCTAButton').addEventListener('mouseleave', () => {
                gsap.to('.contributeCTAButton', {duration: 0.5, boxShadow: '0px 0px #f26b30', x: 0, y: 0})

                gsap.to('#CTATriangleRight', {duration: 0.5, x: 0})
                gsap.to('#CTATriangleLeft', {duration: 0.5, x: -40})
                gsap.to('.contributeCTAText', {duration: 0.5, x: 0})
            })

            document.querySelector('.contributeCTAButton').addEventListener('click', () => {
                gsap.to('.contributeCTAButton', {duration: 0.15, boxShadow: '0px 0px #f26b30', x: 0, y: 0})
                gsap.to('.contributeCTAButton', {duration: 0.15, delay: 0.15, boxShadow: '-4px 4px #f26b30', x: 4, y: -4})
            })
        }

        contributeCTAAnimations()

        // Menu Button Animations
        let isMenuOpen = false

        const menuButtonAnimations = () => {
            document.querySelector('.menuButton').addEventListener('mouseenter', () => {
                if (isMenuOpen == false) {
                    gsap.to('.menuButton', {duration: 0.5, boxShadow: '-4px 4px #3d7c4a', x: 4, y: -4})
                }
                else {
                    gsap.to('.menuButton', {duration: 0.5, boxShadow: '-4px 4px #292c56', x: 4, y: -4})
                }
            })

            document.querySelector('.menuButton').addEventListener('mouseleave', () => {
                if (isMenuOpen == false) {
                    gsap.to('.menuButton', {duration: 0.5, boxShadow: '0px 0px #3d7c4a', x: 0, y: 0})
                }
                else {
                    gsap.to('.menuButton', {duration: 0.5, boxShadow: '0px 0px #292c56', x: 0, y: 0})
                }
            })

            document.querySelector('.menuButton').addEventListener('click', () => {
                if (isMenuOpen == false) {
                    isMenuOpen = true
                    gsap.to('.menuButton', {duration: 0.15, borderColor: '#292c56', boxShadow: '0px 0px #292c56', x: 0, y: 0, borderRadius: '0'})
                    gsap.to('.menuButton', {duration: 0.15, delay: 0.15, boxShadow: '-4px 4px #292c56', x: 4, y: -4})
                    gsap.to('.menuButtonBars', {duration: 0.15, borderColor: '#292c56', x: 0, y: 0, borderRadius: '0'})
                }
                else {
                    isMenuOpen = false
                    gsap.to('.menuButton', {duration: 0.15, borderColor: '#3d7c4a', boxShadow: '0px 0px #3d7c4a', x: 0, y: 0, borderRadius: '50%'})
                    gsap.to('.menuButton', {duration: 0.15, delay: 0.15, boxShadow: '-4px 4px #3d7c4a', x: 4, y: -4})
                    gsap.to('.menuButtonBars', {duration: 0.15, borderColor: '#3d7c4a', x: 0, y: 0, borderRadius: '0'})
                }
                
            })
        }

        menuButtonAnimations()

        /**
         * Animate
         */
        const clock = new THREE.Clock()

        const tick = () =>
        {
            const elapsedTime = clock.getElapsedTime()

            // Camera MouseMove
            camera.rotation.y = -mouse.x * 0.015 * window.innerWidth/window.innerHeight
            camera.rotation.x = mouse.y * 0.015

            // Mesh Rotations
            for (let i = 0; i < meshGroup.length; i++) {
                meshGroup[i].rotation.set(meshRotations[i][0], Math.sin(elapsedTime) * meshRotations[i][1], elapsedTime * meshRotations[i][2])
            }

            // Whale
            if (mouseOnWhale == true) {
                if (whaleTime == 0) {
                    whaleTime = elapsedTime
                }
                gsap.to('#whale', {duration: 1, rotationZ: Math.sin(elapsedTime - whaleTime)*5 - 20, y: Math.sin(elapsedTime - whaleTime) * 10 - 50})
            }

            // Tree
            if (mouseOnTree == true) {
                if (treeTime == 0) {
                    treeTime = elapsedTime
                }
                gsap.to('#tree', {duration: 1, x: Math.cos((elapsedTime - whaleTime)) * 5, scale: 1.15})
            }
            else {
                gsap.to('#tree', {duration: 1, scale: 1})
            }

            // Hero CTA
            gsap.to('.hoverCTA', {rotateZ: elapsedTime * 20})

            // Bottom Triangle
            gsap.to('#bottomTriangleRight', {x: Math.sin(elapsedTime) * 2})

            // Update controls
            if (controls.enabled == true) {
                controls.update()
            }

            // Render
            renderer.render(scene, camera)

            // Call tick again on the next frame
            window.requestAnimationFrame(tick)
        }

        tick()
    }

    mainInteractionsCode()

    // Cursor Follower Code
    const cursorFollowerCode = () => {
        // Scroll Triggers
        gsap.registerPlugin(ScrollTrigger)

        // -----------------------------------------------------------------
        /**
         * Base
         */

        // Canvas
        const canvas = document.querySelector('.cursorFollower')

        // Scene
        const scene = new THREE.Scene()
        // scene.background = new THREE.Color(0xF8F0E3)

        /**
         * Loaders
         */
        // Loading Manager
        const loadingBar = document.getElementById('loadingBar')
        const loadingPage = document.getElementById('loadingPage')

        const loadingManager = new THREE.LoadingManager(
            // Loaded
            () => {
            
            },
            // Progress
            (itemUrl, itemsLoaded, itemsTotal) => {

            }
        )

        // Texture loader
        const textureLoader = new THREE.TextureLoader()

        // Logo Texture Loads
        const circleSmall = textureLoader.load('./images/No BG/GreenCircle.png')
        const triSmall = textureLoader.load('./images/No BG/OrangeTriangle.png')
        const rectSmall = textureLoader.load('./images/No BG/BlueRectangle.png')
        const followerImages = [circleSmall, triSmall, rectSmall]
        const logoColors = [new THREE.Color(0x3d7c4a).convertSRGBToLinear(), new THREE.Color(0xf26b30).convertSRGBToLinear(), new THREE.Color(0x292c56).convertSRGBToLinear()]

        // Draco loader
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('draco/')

        // GLTF loader
        const gltfLoader = new GLTFLoader(loadingManager)
        gltfLoader.setDRACOLoader(dracoLoader)

        // Font Loader
        const fontLoader = new FontLoader()

        // Lighting

        const ambientLight = new THREE.AmbientLight(0xffffff, 1)
        scene.add(ambientLight)

        /**
         * Sizes
         */
        const sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        window.addEventListener('resize', () => {    
            // Update sizes
            sizes.width = window.innerWidth
            sizes.height = window.innerHeight

            // Update camera
            camera.aspect = sizes.width / sizes.height
            camera.updateProjectionMatrix()

            // Update renderer
            renderer.setSize(sizes.width, sizes.height)
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        })

        // Objects
        const cameraGroup = new THREE.Group

        // pointsParameters
        const pointsParameters = {
            countX: 90,
            countY: 60,
            size: 0.05,
            affectedSize: 0.1,
            spacing: 0.2,
            aspect: window.innerWidth/window.innerHeight,
            radius: 0.05,
            groupDiameter: 3,
            pull: 5,
            duration: 0.5
        }

        // Initializations
        const particleGroup = []
        const originalPositions = []

        const count = pointsParameters.countX * pointsParameters.countY
        let j = 0
        let k = 0

        let followerColorIndex = 0

        // Make Particles
        for (let i = 0; i < count; i++) {
            // Center Particles
            const vertices = []
            vertices.push(0,0,0)

            const particleG = new THREE.BufferGeometry()
            particleG.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))

            const particleM = new THREE.PointsMaterial({
                opacity: 0,
                color: logoColors[followerColorIndex],
                size: pointsParameters.size,
                depthWrite: false,
                sizeAttenuation: true,
                transparent: true,
                map: followerImages[followerColorIndex],
            })

            if (followerColorIndex == 2) {
                followerColorIndex = 0
            }
            else {
                followerColorIndex++
            }
            
            const particles = new THREE.Points(particleG, particleM)

            j++

            if ((i)%pointsParameters.countX == 0) {
                j = j - pointsParameters.countX
                k++
                
                if (followerColorIndex == 2) {
                    followerColorIndex = 0
                }
                else {
                    followerColorIndex++
                }
            }

            // Particle Position
            const x = (j + (pointsParameters.countX-1)/2) * pointsParameters.spacing
            const y = (k - (pointsParameters.countY+1)/2) * pointsParameters.spacing
            const z = 0

            // Save Original Positions
            originalPositions[i] = [x,y]

            particleGroup.push(particles)
            particleGroup[i].position.set(x,y,z)
            scene.add(particles)
            cameraGroup.add(particles)
        }

        // --------------------

        /**
         * Camera
         */
        // Base camera
        const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
        camera.position.set(0,0,5)
        scene.add(camera)

        // Controls
        const controls = new OrbitControls(camera, canvas)
        controls.enabled = false

        controls.enableDamping = true
        controls.maxPolarAngle = Math.PI/2
        // controls.minAzimuthAngle = Math.PI*0/180
        // controls.maxAzimuthAngle = Math.PI*90/180
        controls.minDistance = 12  
        controls.maxDistance = 80

        /**
         * Renderer
         */
        const renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            antialias: true,
            alpha: true,
            gammaOutput: true,
            gammaFactor: 2.2
        })
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        renderer.shadowMap.enabled = true
        renderer.shadowMap.type = THREE.PCFSoftShadowMap
        renderer.outputEncoding = THREE.sRGBEncoding
        renderer.toneMapping = THREE.CineonToneMapping

        // Parallax Camera Group
        cameraGroup.add(camera)
        scene.add(cameraGroup)

        // Events
        const mouse = {
            x: 0,
            y: 0
        }

        // Raycaster Events
        const raycaster = new THREE.Raycaster()
        const pointer = new THREE.Vector3()
        const point = new THREE.Vector3()

        window.addEventListener('pointermove', (e) => {
            // 3D
            // Update Pointer Coordinates
            pointer.set(
            ( e.clientX / window.innerWidth ) * 2 - 1,
            - ( e.clientY / window.innerHeight ) * 2 + 1,
            0.5
            )

            // Match Mouse and 3D Pointer Coordinates
            pointer.unproject(camera)
            pointer.sub(camera.position).normalize()
            let distance = -(camera.position.z) / pointer.z
            point.copy(camera.position).add((pointer.multiplyScalar(distance)))

            // Check for Affected Particles
            for (let i = 0; i < particleGroup.length; i++) {
                const distanceFromPointerSquared = (particleGroup[i].position.x - pointer.x)**2 + (particleGroup[i].position.y - pointer.y)**2
                const directionVector = new THREE.Vector2(particleGroup[i].position.x - pointer.x, particleGroup[i].position.y - pointer.y)

                // Case: Affected
                if (distanceFromPointerSquared < pointsParameters.radius) {
                    // Spread
                    gsap.to(particleGroup[i].position, {duration: 0.1, x: particleGroup[i].position.x - directionVector.x * 0, y: particleGroup[i].position.y - directionVector.y * 0})
                    gsap.to(particleGroup[i].position, {duration: pointsParameters.duration, delay: 0, x: originalPositions[i][0], y: originalPositions[i][1]})

                    gsap.to(particleGroup[i].material, {duration: 0.1, opacity: 1})
                    gsap.to(particleGroup[i].material, {duration: pointsParameters.duration, delay: 0.1, opacity: 0})

                    gsap.to(particleGroup[i].material, {duration: 0.2, size: pointsParameters.affectedSize})
                    gsap.to(particleGroup[i].material, {duration: 1, delay: 0.1, size: pointsParameters.size})
                }
            }    
        })

        document.addEventListener('click', () => {
            // Check for Affected Particles
            for (let i = 0; i < particleGroup.length; i++) {
                const distanceFromPointerSquared = (particleGroup[i].position.x - pointer.x)**2 + (particleGroup[i].position.y - pointer.y)**2
                const directionVector = new THREE.Vector2(particleGroup[i].position.x - pointer.x, particleGroup[i].position.y - pointer.y)

                // Case: Affected
                if (distanceFromPointerSquared < pointsParameters.radius) {
                    // Spread
                    gsap.to(particleGroup[i].position, {duration: 0.1, x: particleGroup[i].position.x - directionVector.x * pointsParameters.pull, y: particleGroup[i].position.y - directionVector.y * pointsParameters.pull})
                    gsap.to(particleGroup[i].position, {duration: pointsParameters.duration, delay: 0, x: originalPositions[i][0], y: originalPositions[i][1]})

                    gsap.to(particleGroup[i].material, {duration: 0.1, opacity: 1})
                    gsap.to(particleGroup[i].material, {duration: pointsParameters.duration, delay: 0.1, opacity: 0})

                    gsap.to(particleGroup[i].material, {duration: 0.1, size: pointsParameters.affectedSize})
                    gsap.to(particleGroup[i].material, {duration: 0.1, delay: 0.1, size: pointsParameters.size})
                }
            } 
        })

        /**
         * Animate
         */
        const clock = new THREE.Clock()

        const tick = () =>
        {
            const elapsedTime = clock.getElapsedTime()

            // Update controls
            if (controls.enabled == true) {
                controls.update()
            }

            // Render
            renderer.render(scene, camera)

            // Call tick again on the next frame
            window.requestAnimationFrame(tick)
        }

        tick()
    }

    cursorFollowerCode()
}

interActiveCode()